// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-strapi-article-slug-js": () => import("./../../../src/pages/article/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-article-strapi-article-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-category-strapi-category-slug-js": () => import("./../../../src/pages/category/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-category-strapi-category-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-service-data-analytics-js": () => import("./../../../src/pages/service/data-analytics.js" /* webpackChunkName: "component---src-pages-service-data-analytics-js" */),
  "component---src-pages-service-data-lake-and-warehouse-js": () => import("./../../../src/pages/service/data-lake-and-warehouse.js" /* webpackChunkName: "component---src-pages-service-data-lake-and-warehouse-js" */),
  "component---src-pages-service-mobile-development-js": () => import("./../../../src/pages/service/mobile-development.js" /* webpackChunkName: "component---src-pages-service-mobile-development-js" */),
  "component---src-pages-service-product-design-js": () => import("./../../../src/pages/service/product-design.js" /* webpackChunkName: "component---src-pages-service-product-design-js" */),
  "component---src-pages-service-web-development-js": () => import("./../../../src/pages/service/web-development.js" /* webpackChunkName: "component---src-pages-service-web-development-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

